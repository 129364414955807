
// this file is no longer required

const x = 2;

// import { GeoLocation } from 'o365-utils';
// import { flutterHasGeolocation, flutterRequestGeolocation } from "apps.modules.FlutterJavaScriptChannels.ts";

// getCurrentPosition as a promise
// includes workaround to handle bug with permission check on iOS
// will prefer flutter channel over web api

// GeoLocation.getCurrentPosition = (options) => {
//     return new Promise(async (resolve, reject) => {
//         if (flutterHasGeolocation()) {
//             try {
//                 const data = await flutterRequestGeolocation();
//                 resolve({
//                     coords: {
//                         latitude: data.latitude,
//                         longitude: data.longitude,
//                         accuracy: data.accuracy,
//                     },
//                     timestamp: data.timestamp,
//                     flutter: true,
//                 });
//             } catch (err) {
//                 reject(err);
//             }
//         } else {
//             if (!navigator?.geolocation?.getCurrentPosition) {
//                 reject({ code: -1, message: "geolocation is not supported" });
//                 return;
//             }

//             // only the first resolve/reject is valid

//             navigator.geolocation.getCurrentPosition(resolve, reject, options);

//             setTimeout(() => {
//                 reject({ code: -1, message: "geolocation took too long" });
//             }, 10000);
//         }
//     });
// };
